// If you want to override variables do it here
@import 'fonts.scss';
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";


// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';

// ie fixes
@import "ie-fix";


html, body {
	font-family: 'Source Sans Pro','Helvetica Neue',Helvetica,Arial,sans-serif;
	color: #333;
	font-size: 1rem;;
}
body {
	background-color: #dddddd;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23000000' fill-opacity='0.03'%3E%3Cpath opacity='.4' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

p {
	margin-bottom: 0;
	&.info {
		font-style: italic;
		font-size: 0.8em;
		margin: 0 1rem;
	}
	&.error {
		font-size: 0.8em;
		font-weight: bold;
		color: $anomaly;
	}
}
.bg-planned { background: $planned; }
.text-planned { color: $planned; }
.bg-waiting { background: $waiting; }
.text-waiting { color: $waiting; }
.bg-closed { background: $closed; }
.text-closed { color: $closed; }
.bg-anomaly { background: $anomaly; }
.text-anomaly { color: $anomaly; }
.bg-cancelled { background: $cancelled; }
.text-cancelled { color: $cancelled; }

.status.PLANNED {
	background: $waiting;
	color: #fff;
}
.status.WAITING {
	background: $planned;
	color: #fff;
}
.status.CLOSED {
	background: $closed;
	color: #fff;
}
.status.ANOMALY {
	background: $anomaly;
	color: #fff;
}
.status.CANCELLED {
	background: $cancelled;
	background: repeating-linear-gradient(
	  45deg,
	  $cancelled,
	  $cancelled 4px,
	  $closed 4px,
	  $closed 8px,
	);
	color: #fff;
}

.btn-cta {
	padding: 0.375rem 1.5rem;
	text-transform: uppercase;
	color: #000;
}

.card-footer:empty {
	display: none !important;
}


.form-control {
	font-size: 1rem !important;
}

.EntityList {
	.table {
		thead tr {
			text-transform: uppercase;
			font-size: 80%;
		}

	}
	.table-bordered th, .table-bordered td {
		border: 1px solid #f1f2f7;
	}
	.table-striped tbody tr:nth-of-type(odd) {
		background-color: #f1f2f7;
	}
	.table-bordered thead th, .table-bordered thead td {
		border-bottom-width: 1px;
	}
	.PaginationCustom {
		margin-top: 1%;
	}
}

.no-select {
	user-select: none;
}

.line-height-1 {
	line-height: 1;
}
.btn-icon {
	display: flex;
	align-items: center;
}

.spinner {
	animation: spinner 2s infinite linear;
}


@keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
				transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
				transform: rotate(359deg);
	}
}
