
$card-footer: #f0f3f5;
$total:  #2f353a;
$total-time:  #4f9075;

$blue: #63C2DE;
$planned: $blue;
$validated: #35c98b;
$new: $blue;

$total:  #2f353a;

$yellow: #FFC107;
$waiting: #FFC107;
$affected: #FFC107;

$gray: #999DA1;
$closed: #999DA1;
$processed: #999DA1;

$red: #d20000 !default;
$anomaly: #F86C6B;
$refused: #F86C6B;

$gray-light: #C8CED3;
$cancelled: #d9dfe4;

$dark: #2f353a;
$success: #70C079 !default;

$gray-light: #e4e5e6 !default;
$gray-dark: #444 !default;

$screen-xs: 575.98px;
$screen-sm: 767.98px;
$screen-md: 991.98px;
$screen-lg: 1199.98px;

$Fondamental: #a4a4a4;
$Métier: #ed7d31;
$Comportemental: #ffc54b;
$famille-Fondamental: #d9d9d9;
$famille-Métier: #f4b084;
$famille-Comportemental: #ffc54b;

/* @coreui variables (includes bootstrap variables) */
@import "~@coreui/coreui/scss/variables";

$theme-colors: map-merge(
  (
	"anomaly": $anomaly,
	"affected": $affected,
	"cancelled": $cancelled,
	"closed": $closed,
	"dark": $dark,
	"new": $new,
	"planned": $planned,
	"processed": $processed,
	"red": $red,
	"refused": $refused,
	"total-time": $total-time,
	"transparent": transparent,
	"waiting": $waiting,
	"Fondamental": $Fondamental,
	"Métier": $Métier,
	"Comportemental": $Comportemental,
	"famille-Fondamental": $famille-Fondamental,
	"famille-Métier": $famille-Métier,
	"famille-Comportemental": $famille-Comportemental,
  ),
  $theme-colors,
);


.alert-success {
	background: $success;
	color: #fff;
}
